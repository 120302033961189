<template>
  <div class="route-table">
    <b-modal v-if="row" :id="modalId" :hide-footer="true" title="Список" size="xl" centered>
      <div class="modal-orders flex">
        <input v-model="searchModal" class="search-modal" type="search" placeholder="поиск..." />
        <button v-if="!row.confirmed" class="btn-modal btn-primary" @click="orderPointsMethod(row.region_id)">
          Добавить заказ
        </button>
        <button v-if="!row.confirmed" class="btn-modal btn-primary" @click="applyPoints(row.id)">
          Подтвердить
        </button>
      </div>
      <table class="table-modal">
        <tr>
          <th>#</th>
          <th>id</th>
          <th>Контерагент</th>
          <th>Адрес</th>
          <th>Вес</th>
          <th>Обьем</th>
          <th>Контейнер</th>
          <th>Действия</th>
          <th>Комментарии</th>
        </tr>
        <template v-if="filterModal && filterModal.length > 0">
          <tr v-for="(subId, index) in filterModal" :key="subId ? subId.id : index">
            <template v-if="subId && subId.id">
              <td>{{ index + 1 }}</td>
              <td>{{ subId.final_name }}</td>
              <td>{{ subId.employee?.name ?? '-' }}</td>
              <td>{{ subId.address }}</td>
              <td>{{ subId.volume }}</td>
              <td>{{ subId.weight }}</td>
              <td>
                <div class="td-containers__block">
                  {{ subId.container_count ? subId.container_count.split(",").join(", ") : "" }}
                </div>
              </td>
              <td>
                <input style="margin-left: 10px; vertical-align: middle;" 
                  v-if="subId && !row.confirmed && subId.id && subId.name !== 'Шымкент Склад' && subId.name !== 'Шымкент'"
                  type="checkbox" v-model="subId.selected">
              </td>
              <td>
                <input class="search-modal" style="margin-left: 1px; width: 100%; vertical-align: middle;" 
                  v-if="subId && !row.confirmed && subId.id && subId.name !== 'Шымкент Склад' && subId.name !== 'Шымкент' && !subId.selected"
                  :key="subId.id"
                  type="text" placeholder="Причина" v-model="subId.comment">
              </td>
            </template>
          </tr>
        </template>
      </table>
    </b-modal>
    <!-- v-on:keydown.enter.prevent="orderSearchMethod(row.id)"
      @focusout="orderSearchMethod(row.id)"
      @keyup="orderSearchMethod(row.id)" -->
    <b-modal id="bv-modal-create-order" hide-footer>
      <input style="width: 100%;" 
      
      placeholder="Поиск" class="search-modal" v-model="searchbar">
      <b-form-select
        v-model="pointId"
        class="modal-orders__add main__select"
        :options="pointsList"
        text-field="f_name"
        value-field="id"
      />
      <b-button v-if="row" class="mt-3" block @click="createOrder(pointId, row.id)">
        Добавить
      </b-button>
    </b-modal>
    <p v-if="ordersActiveProducts && ordersActiveProducts.meta" class="pagination__text">
      {{ ordersActiveProducts.meta.from }}-{{ ordersActiveProducts.meta.to }} из
      {{ ordersActiveProducts.meta.total }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="10000"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pagination"
      @input="changePage()"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon"
          src="@/assets/png/database-icon-active.png"
          style="transform: rotate(180deg)"
          alt=""
        />
      </template>
      <template #next-text>
        <img
          class="pagination__btn-icon"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
       style="white-space: nowrap;"
        v-if="ordersActiveProducts && ordersActiveProducts.data"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="filter"
        :fields="fields"
        small
      >
        <template #cell(id)="data">
          <div
            v-if="data.item.crossdocking_id"
            style="cursor: pointer"
            @click="$router.push(`/route/${data.item.id}`)"
          >
            {{ code.slice(0, 10 - data.item.crossdocking_id.toString().length)
            }}{{ data.item.crossdocking_id }}/{{ data.item.crossdocking_number }}
          </div>
          <div v-else style="cursor: pointer" @click="$router.push(`/route/${data.item.id}`)">
            {{ code.slice(0, 10 - data.item.id.toString().length) }}{{ data.item.id }}
          </div>
        </template>
        <template #cell(description)="data">
          <button class="table-name" @click="chooseRow(data.item)">
            <p>{{ data.item.description }}</p>
            <div class="table-box flex">
            </div>
          </button>
        </template>
        <template #cell(on_schedule)="data">
          <span>{{ day(data.item.day) }}</span>
        </template>
        <template #cell(y)="data">
          <button class="route-btn-yes mr-2" @click="driversChanges(data.item, true)" :disabled="checkAllow" >
            {{ data.item.confirmed ? "Подтвержден" : "Да" }}
          </button>
          <button
            v-if="!data.item.confirmed"
            class="route-btn-no"
            :disabled="checkAllow"
            @click="$bvModal.show(`bv-${data.item.id}`)"
          >
            Нет
          </button>
          <b-modal :id="`bv-${data.item.id}`" title="Выберите дату" hide-footer>
            <vc-date-picker v-model="range" mode="dateTime" :model-config="modelConfig" color="green" is24hr :rows="1" />
            <b-button :disabled="range == null" class="route-btn-yes mt-3" block @click="dateChange(data.item.id, range)">
              Отправить
            </b-button>
            <b-button class="route-btn mt-3" block @click="deleteRoute(data.item.id)">
              Удалить
            </b-button>
          </b-modal>
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.volume < 1.6 ">хэтчбек</span>
          <span v-if="data.item.volume < 21 && data.item.volume > 1.6">фургон</span>
          <span v-if="data.item.volume > 21 && data.item.volume < 30">фура</span>
          <span v-if="data.item.volume > 30">рефрижератор</span>
        </template>
        <template #cell(driver)="data">
          <button
            :disabled="!data.item.confirmed"
            v-if="checkAllow === false"
            class="route-btn-launch default-btn"
            @click="modalDrivers(data.item.id, 'driver', data.item.weight, data.item.volume)"
          >
            Распределить
          </button>
          <ModalBlock
            v-if="modalItem === data.item.id && modalType === 'driver'"
            :data="data"
            :modal="'driver'"
            :weight="data.item.weight"
            @close="modalClose()"
          />
        </template>
        <template #cell(region_name)="data">
          <p>{{ data.item.region_name }}</p>
        </template>
        <template #cell(start_date)="data">
          <p>
            {{ dateProduct(data.item.start_date) }}
            {{ timeProduct(data.item.start_date) }}
          </p>
        </template>
        <template #cell(date-p)="data">
          <p>{{ dateProduct(data.item.eta) }} {{ timeProduct(data.item.eta) }}</p>
        </template>
        <template  #cell(order_type)="data">
          <p v-if="data.item.order_type === 3">
            Обратный
          </p>
          <p v-else-if="data.item.order_type === 2">
            Множественный
          </p>
          <p v-else>
            Стандартный
          </p>
        </template>
        <template #cell(order_status)="data">
          <p>
            {{
              data.item.order_status
                ? `${data.item.order_status.user.name} ${data.item.order_status.user.surname}`
                : ""
            }}
          </p>
        </template>
        <template #cell(order-eta)="data">
          <button class="route-btn-launch default-btn" @click="orderEta(data.item.id)">
            Запустить
          </button>
        </template>
        <template #cell(action)="data">
          <div class="">
            <a @click="deleteRoute(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" variant="danger" />
            </a>
          </div>
        </template>
        <template #cell(weight)="data">
          <div>
            <span v-if="data.item.order_weight" style="font-weight: 600; font-size: 1.05em"
              >{{ data.item.order_weight }}т/</span
            >
            <span>{{ parseFloat(data.item.weight).toFixed(2) }} кг</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="cell-route-status">
            <span
              v-if="data.item.status"
              :style="{
                backgroundColor: statusColor(data.item.status),
                color: statusColorText(data.item.status),
              }"
            >
              {{ statusChange(data.item.status) }}
            </span>
          </div>
        </template>
      </b-table>
    </div>
    <div v-if="modalItem && modalType" class="close-black" @click="modalClose()" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import statusMixin from "@/mixins/status";

export default {
  components: {
    ModalBlock: () => ({
      component: import("../components/modal"),
    }),
  },
  mixins: [statusMixin],
  data() {
    return {
      searchbar: '',
      dispatchCounter: 0,
      textSearchTimer: null,
      perPage: 15,
      searchModal: null,
      pointId: null,
      currentPage: 1,
      modalId: null,
      row: null,
      modalItem: null,
      modalType: null,
      range: new Date().toLocaleString("kz-KZ", {timeZone: "Asia/Qyzylorda"}),
      code: "0000000000",
      temp: null,
      allowRoles: [
          1, 2, 3
      ],
      fields: [
        {
          key: "id",
          label: "№ маршрута",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "region_name",
          label: "Направления",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "description",
          label: "Номер Заказа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "weight",
          label: "Общий вес",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "on_schedule",
          label: "Выезд по графику",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "start_date",
          label: "Дата выезда",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "date-p",
          label: "Дата прогноз прибытия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "y",
          label: "Подтвердить маршрут",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-md",
        },
        {
          key: "type",
          label: "Вид",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "driver",
          label: "Распределить",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "order_type",
          label: "Тип заказа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "order_status",
          label: "Водитель",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "transport.transport.number",
          label: "Транспорт",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },

    };
  },
  computed: {
    ...mapState([
      "ordersActiveProducts",
      "textFilter",
      "routeStatusFilter",
      "routeAddressFilter",
      "dateFilter",
      "orderPoints",
      "profileProducts"
    ]),
    filter() {
      if (this.ordersActiveProducts) {
        let data = this.ordersActiveProducts.data
        let productFilter = [...data];
        return productFilter;
      } else return null;
    },
    filterModal() {
      let productFilter = [...this.row.points];
      productFilter = productFilter.filter((el) => el || false);

      if (this.searchModal) {

        productFilter = productFilter.filter((item) =>
          item.final_name?.toLowerCase().includes(this.searchModal.toLowerCase())
          || item.address?.toLowerCase().includes(this.searchModal.toLowerCase())
          || item.employee?.name?.toLowerCase().includes(this.searchModal.toLowerCase())
        );
      }

      return productFilter;
    },
    pointsList() {
      if (this.orderPoints) {

        let data = this.orderPoints.data.map(v => ({...v, f_name: v.final_name + ', из ' + v.from + ' в ' + v.to + ', статус: ' + v.status }));

        if (this.searchbar.length > 0) {

          data = data.filter((item) => item.final_name?.toLowerCase().includes(this.searchbar?.toLowerCase())
          || item.address?.toLowerCase().includes(this.searchbar?.toLowerCase())
          || item.employee?.name?.toLowerCase().includes(this.searchbar?.toLowerCase()));

          console.log(data.length);
          
        }

        return [
          {
            id: null,
            name: "Выберите заказ",
            final_name: "Выберите заказ",
            f_name: "Выберите заказ",
            disabled: true,
          },
          ...data,
        ];
      }
      return [
        {
          id: null,
          name: "Выберите заказ",
          final_name: "Выберите заказ",
          f_name: "Выберите заказ",
          disabled: true,
        },
      ];
    },
    checkAllow() {
      return !this.allowRoles.includes(this.profileProducts.position.id)
    }
  },
  watch: {
    async currentPage() {
      this.search(this.currentPage);
    },
    filter() {
    },
    textFilter() {
      this.dispatchCounter++;
      if (this.dispatchCounter !== 1) {
        if (this.textSearchTimer !== null) {
          clearTimeout(this.textSearchTimer);
        }
        if (this.textFilter.length === 0) {
          (async () => {
            this.search();
          })();
        } else {
          this.textSearchTimer = setTimeout(async () => {
            await this.search();
            this.currentPage = 1;
          }, 1500);
        }
      }
      if (this.dispatchCounter > 9999) this.dispatchCounter = 1;
    },
    async routeStatusFilter() {
      await this.search();
      this.currentPage = 1;
    },
    async routeAddressFilter() {
      await this.search();
      this.currentPage = 1;
    },
    async dateFilter() {
      await this.search();
      this.currentPage = 1;
    },
  },
  created() {
    if (this.$route.query.page != undefined) {
      this.currentPage = this.$route.query.page
    }

    if(!this.ordersActiveProducts) {
      this.$store.dispatch("getUsersList");
      this.search(this.currentPage);
    } 
  },
  methods: {
    async search(page = 1) {
      await this.$store.dispatch("getOrdersActiveProducts", {
        name: this.textFilter,
        order_status: this.routeStatusFilter,
        page,
        paginate_page: this.perPage,
        from_date: this.dateFilter ? this.dateFilter?.start + " 00:00:00" : "",
        to_date: this.dateFilter ? this.dateFilter.end + " 00:00:00" : "",
        region_id: this.routeAddressFilter,
      });
    },
    modalDrivers(index, value, weight, volume) {
      this.getTransports(weight, volume)
      this.modalItem = index;
      this.modalType = value;
    },
    modalClose() {
      this.modalItem = null;
      this.modalType = null;
    },
    driversChanges(item, value) {
      if (item.confirmed == true) {
        return;
      }
      this.$bvModal.hide("bv-modal-no");
      this.$api
        .post("/web/order-confirm", {
          order_id: item.id,
          confirm: value,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          this.search();
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    dateProduct(value) {
      if (!value) {
        return "";
      }
      const date = value.split(" ")[0].split("-");
      return date[2] + "." + date[1] + "." + date[0];
    },
    timeProduct(value) {
      if (!value) {
        return "";
      }
      const time = value.split(" ")[1].split(":");
      return time[0] + ":" + time[1];
    },
    addToFav(item) {
      item.fav = !item.fav;
      this.$api
        .post("/web/order-add-fav", {
          order_id: item.id,
          fav: item.fav ? "" : "false",
        })
        .then(() => {
          this.$toast.success("Успешно");
        })
        .catch((e) => {
          if (e.response) {
            item.fav = !item.fav;
            this.$toast.error(e.response.data.message);
          }
        });
    },
    addToPriority(item) {
      item.priority = item.priority === "default" ? "flash" : "default";
      this.$api
        .post("/web/order-add-priority", {
          order_id: item.id,
          priority: item.priority,
        })
        .then(() => {
          this.$toast.success("Успешно");
        })
        .catch((e) => {
          if (e.response) {
            this.$toast.error(e.response.data.message);
            item.priority = item.priority === "default" ? "flash" : "default";
          }
        });
    },
    chooseRow(item) {
      this.row = item;
      this.row.points = this.row.points.map(v => ({...v, selected: true, comment: ''}))
      this.modalId = "modal-" + item.id;
      setTimeout(() => {
        this.$bvModal.show(this.modalId);
      }, 100);
    },
    async orderPointsMethod(city) {
      this.$bvModal.show("bv-modal-create-order");
      await this.$store.dispatch("getOrderPoints", { region_id: city });
    },
    async orderSearchMethod(city) {
      if (!this.searchbar.length)
        await this.$store.dispatch("getOrderPoints", { region_id: city });
      
      if (this.searchbar.length > 4){
        if (this.temp !== this.searchbar)
          await this.$store.dispatch("getOrderPoints", { region_id: city, search: this.searchbar });

        this.temp = this.searchbar;
      }
    },
    createOrder(pointId, orderId) {
      this.$api
        .post("/web/add-point-order", {
          point_id: pointId,
          order_id: orderId,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          this.$bvModal.hide("bv-modal-create-order");
          this.$bvModal.hide(`modal-${orderId}`);
          this.search();
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    applyPoints(orderId) {
      let points = this.filterModal.filter(
        (i) => i.selected === true
      );
      this.$api
        .post("/web/point-orders-apply", {
          points: points.map(item =>{return item.id}),
          unset_points: this.filterModal.filter(
            (i) => i.selected === false || i.selected === undefined
          ).map(item =>{return {id:item.id, comment: item.comment}}),
          order_id: orderId,
        })
        .then(() => {
          this.row = null;
          this.search();
        })
        .catch(() => {
          this.$toast.error('Выберите заказы или введите комментарии');
        });
    },
    orderEta(orderId) {
      this.$api
        .post("/web/order-eta", {
          order_id: orderId,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          this.search();
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    deletePoint(value, index) {
      if (value && index) {
        this.$api
          .post("/web/point-delete", {
            point_id: value,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно");
            this.row.points.splice(index, 1);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    async deleteRoute(id) {
      this.$bvModal.hide(`bv-${id}`)
      await this.$api.delete(`/web/order-delete?order_id=${id}`).then(() => {
        this.$toast.success("Успешно");
      });
      await this.search();
    },
    day(value) {
      switch (value) {
        case "isMonday":
          return "Пн";
        case "isTuesday":
          return "Вт";
        case "isWednesday":
          return "Ср";
        case "isThursday":
          return "Чт";
        case "isFriday":
          return "Пт";
        case "isSaturday":
          return "Сб";
        case "isSunday":
          return "Вс";
        default:
          return "Нет";
      }
    },
    getTransports(weight, volume){
      this.$store.dispatch("getTransportList", {
        type: 'get_not_occupied',
        weight: weight,
        volume: volume
      });
    },
    dateChange(item, value) {
      this.$bvModal.hide(`bv-${item}`)
      this.$api
        .post("/web/order-date-change", {
          order_id: item,
          date: value,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          this.search();
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
  },
};
</script>

<style>
.td-containers__block {
  box-sizing: content-box;
  max-width: 16em;
  max-height: 6em;
  /* display: block; */
  border: none;
  overflow-y: auto;
}
</style>

<style scoped>
.cell-route-status span {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
}
.table-name {
  background: transparent;
  width: 100%;
  text-align: start;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: var(--secondary-color);
  position: relative;
  /*padding: 0;*/
  padding-right: 30px;
}
.table-name:focus {
  outline: none;
  box-shadow: none;
}
.table-name p {
  font-size: 10px;
  line-height: 12px;
  color: #acacac;
}
.table-box {
  /*width: 25px;*/
  position: absolute;
  top: 0;
  right: 0;
}
.table-btn__status {
  text-align: center;
  color: #fff;
  margin: auto;
}
.table-modal {
  max-width: 100%;
  font-size: 12px;
}
.table-modal th {
  padding: 5px 10px;
  font-weight: 500;
}
.table-modal td {
  padding: 5px 10px;
}
.table-modal,
.table-modal th,
.table-modal td {
  border: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
}
.table-pagination {
  margin: 15px 0;
  padding: 15px 5px 15px 15px;
  background: #e0e9fa;
  border-radius: 15px;
}
.pagination__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #306cdc;
}
.pagination__box {
  width: 300px;
  height: 30px;
}
.pagination__text-inner {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #707070;
}
.pagination__select-block {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  color: #707070;
  width: 60px;
  margin: 0 25px 0 10px;
  color: #83b1f4;
}
.pagination,
.pagination__text {
  position: absolute;
  top: -151px;
  right: 10px;
}
.pagination__text {
  position: absolute;
  top: -139px;
  right: 130px;
}
.pagination__select {
  border: 1px solid #83b1f4;
  color: #83b1f4;
}
.main__select-icon {
  right: 8px;
}
.page-item {
  margin-right: 10px;
  border: 1px solid #83b1f4;
  border-radius: 5px;
}
.page-item:nth-child(2) {
  display: none;
}
.pagination__btn-icon-left {
  transform: rotate(180deg);
}
.working__road {
  margin: 0;
}
.table__road-block {
  width: 95%;
}
.table__road-block::before {
  right: 5px;
  top: -3px;
}
.drivers {
  width: 500px;
  max-width: 90%;
  background: #ffffff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  padding: 1px 10px;
  padding-top: 10px;
}
.drivers__list {
  overflow-y: auto;
  max-height: 360px;
}
.drivers__item {
  padding: 10px 5px;
  min-height: 44px;
  border-top: 1px solid #4a504c36;
  cursor: pointer;
}
.drivers__item:hover {
  background-color: rgba(83, 89, 85, 0.2);
}
.drivers__item-active {
  background-color: rgba(83, 89, 85, 0.2);
}
.drivers__btn-group {
  display: flex;
  justify-content: flex-start;
  margin: 24px 0 10px 0;
}
.drivers__name {
  font-size: 18px;
  font-weight: bold;
}
.drivers__text {
  font-size: 12px;
  color: #777977;
}
.table-box img {
  width: 12px;
}
.orders-list {
  font-size: 12px;
}
.orders-list span:not(:last-child):after {
  content: ", ";
}
.search-modal {
  width: 70%;
  height: 30px;
  padding: 5px 10px;
  margin-bottom: 14px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
  color: #000;
}
.btn-modal {
  width: 15%;
  height: 30px;
  margin: 0 0px 15px 8px;
  border-radius: 5px;
  font-size: 14px;
}
.route-btn-yes {
  padding: 0 16px;
  min-width: 51px;
  height: 36px;

  font-style: normal;
  text-align: center;
  /* color: #ffffff; */
  /* background: linear-gradient(90deg, #00cdac 0%, #8ddad5 100%); */
  background: var(--secondary-color-light);
  color: var(--secondary-color);
  box-shadow: 0px 2px 4px rgba(37, 183, 67, 0.12);
  border-radius: 16px;
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.route-btn-no {
  padding: 9px 16px;
  width: 57px;
  height: 36px;
  background: #ffffff;
  border: 1px solid var(--secondary-color);
  border-radius: 16px;
  color: var(--secondary-color);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.route-btn-launch {
  height: 36px;
  font-size: 14px;
  padding: 8px 10px;
}
</style>
